<template>
  <div class="main">
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>基地信息</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-card
      shadow="hover"
      class="card-sty"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div v-auth="'farmArea-add'">
          <el-button type="primary" size="small" @click="addFarmArea"
            >新增基地</el-button
          >
          &nbsp;
        </div>
            <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
              v-loading="spinning"
              :data="data"
              row-key="id"
            >
              <el-table-column prop="name" min-width="120" label="基地简称">
              </el-table-column>
              <el-table-column prop="fullName" min-width="120" label="基地名称">
              </el-table-column>
              <el-table-column prop="contact" label="基地负责人">
              </el-table-column>
              <el-table-column prop="address" min-width="120" label="地址">
              </el-table-column>
              <el-table-column prop="acreage" label="总面积"> </el-table-column>
              <el-table-column prop="plantAcreage" label="种植面积">
              </el-table-column>
              <el-table-column prop="elevation" label="海拔"> </el-table-column>
              <el-table-column prop="rent" label="地租"> </el-table-column>
              <el-table-column prop="terrainText" label="地势">
              </el-table-column>
              <el-table-column prop="plantingTimeText" label="种植季节">
              </el-table-column>
              <el-table-column prop="irrigationMethodText" label="灌溉方式">
              </el-table-column>
              <el-table-column prop="waterConditionsText" label="水源状况">
              </el-table-column>
              <el-table-column prop="soilConditionsText" label="土壤状况">
              </el-table-column>
              <el-table-column
                fixed="right"
                width="200"
                label="操作"
                align="center"
                v-if="authVerify.verify('farmArea-edit,farmArea-delete')"
              >
                <template slot-scope="scope">
                  <el-button
                    icon="el-icon-edit-outline"
                    size="mini"
                    @click="editFarmArea(scope.row)"
                    v-auth="'farmArea-edit'"
                  >
                    编辑
                  </el-button>
                  <el-button
                    icon="el-icon-delete"
                    size="mini"
                    @click="deleteFarmArea(scope.row)"
                    v-auth="'farmArea-delete'"
                  >
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
      </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-drawer
          title="新增基地"
          width="800"
          placement="right"
          :closable="false"
          :visible="addFarmAreaVisible"
          @close="onAddFarmAreaDrawerClose"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="farmAreaFormValue"
              ref="farmAreaRefForm"
              :rules="farmAreaRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-form-model-item label="简称" prop="name">
                <a-input v-model="farmAreaFormValue.name" placeholder="请输入简称"/>
              </a-form-model-item>
              <a-form-model-item label="全称" prop="fullName">
                <a-input v-model="farmAreaFormValue.fullName" placeholder="请输入全称"/>
              </a-form-model-item>
              <a-form-model-item label="基地负责人" prop="contact">
                <a-input v-model="farmAreaFormValue.contact" placeholder="请输入基地负责人"/>
              </a-form-model-item>
              <a-form-model-item label="地址" prop="address">
                <a-input v-model="farmAreaFormValue.address" placeholder="请输入地址"/>
              </a-form-model-item>
              <a-form-model-item label="面积" prop="acreage">
                <a-input v-model="farmAreaFormValue.acreage" type="number" placeholder="请输入面积(数字)"/>
              </a-form-model-item>
              <a-form-model-item label="种植" prop="plantAcreage">
                <a-input v-model="farmAreaFormValue.plantAcreage" placeholder="请输入种植面积"/>
              </a-form-model-item>
              <a-form-model-item label="地租" prop="rent">
                <a-input v-model="farmAreaFormValue.rent" placeholder="请输入地租"/>
              </a-form-model-item>
              <a-form-model-item label="海拔" prop="elevation">
                <a-input v-model="farmAreaFormValue.elevation" placeholder="请输入海拔"/>
              </a-form-model-item>
              <a-form-model-item label="地势" prop="terrainList">
                <a-checkbox
                  :checked="farmAreaFormValue.terrainList.includes('FLAT')"
                  @change="terrainListCheckboxChange('FLAT')"
                >
                  平地
                </a-checkbox>
                <a-checkbox
                  :checked="farmAreaFormValue.terrainList.includes('SLOPING')"
                  @change="terrainListCheckboxChange('SLOPING')"
                >
                  坡地
                </a-checkbox>
                <a-checkbox
                  :checked="farmAreaFormValue.terrainList.includes('TERRACED')"
                  @change="terrainListCheckboxChange('TERRACED')"
                >
                  梯田
                </a-checkbox>
              </a-form-model-item>
              <a-form-model-item label="种植季节" prop="plantingTimeList">
                <a-checkbox
                  :checked="
                    farmAreaFormValue.plantingTimeList.includes('SPRING')
                  "
                  @change="plantingTimeListCheckboxChange('SPRING')"
                >
                  春
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.plantingTimeList.includes('SUMMER')
                  "
                  @change="plantingTimeListCheckboxChange('SUMMER')"
                >
                  夏
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.plantingTimeList.includes('AUTUMN')
                  "
                  @change="plantingTimeListCheckboxChange('AUTUMN')"
                >
                  秋
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.plantingTimeList.includes('WINTER')
                  "
                  @change="plantingTimeListCheckboxChange('WINTER')"
                >
                  冬
                </a-checkbox>
              </a-form-model-item>

              <a-form-model-item label="灌溉方式" prop="irrigationMethodList">
                <a-checkbox
                  :checked="
                    farmAreaFormValue.irrigationMethodList.includes('TRICKLE')
                  "
                  @change="irrigationMethodListCheckboxChange('TRICKLE')"
                >
                  滴灌
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.irrigationMethodList.includes(
                      'SPRINKLING'
                    )
                  "
                  @change="irrigationMethodListCheckboxChange('SPRINKLING')"
                >
                  喷灌
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.irrigationMethodList.includes('FURROW')
                  "
                  @change="irrigationMethodListCheckboxChange('FURROW')"
                >
                  沟灌
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.irrigationMethodList.includes('MANPOWER')
                  "
                  @change="irrigationMethodListCheckboxChange('MANPOWER')"
                >
                  人工
                </a-checkbox>
              </a-form-model-item>
              <a-form-model-item label="水源状况" prop="waterConditionsList">
                <a-checkbox
                  :checked="
                    farmAreaFormValue.waterConditionsList.includes('RIVER')
                  "
                  @change="waterConditionsListCheckboxChange('RIVER')"
                >
                  河流溪流
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.waterConditionsList.includes('LAKES')
                  "
                  @change="waterConditionsListCheckboxChange('LAKES')"
                >
                  湖泊水库
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.waterConditionsList.includes('POND')
                  "
                  @change="waterConditionsListCheckboxChange('POND')"
                >
                  池塘
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.waterConditionsList.includes(
                      'UNDERGROUND'
                    )
                  "
                  @change="waterConditionsListCheckboxChange('UNDERGROUND')"
                >
                  地下水
                </a-checkbox>
              </a-form-model-item>
              <a-form-model-item label="土壤状况" prop="soilConditionsList">
                <a-checkbox
                  :checked="
                    farmAreaFormValue.soilConditionsList.includes('CLAY')
                  "
                  @change="soilConditionsListCheckboxChange('CLAY')"
                >
                  粘土
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.soilConditionsList.includes('LOAM')
                  "
                  @change="soilConditionsListCheckboxChange('LOAM')"
                >
                  壤土
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.soilConditionsList.includes('SANDY')
                  "
                  @change="soilConditionsListCheckboxChange('SANDY')"
                >
                  沙土
                </a-checkbox>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="addFarmAreaVisible = false"
              >取消</a-button
            >
            <a-button type="primary" @click="toAddFarmArea">确认</a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-drawer
          title="编辑基地"
          width="800"
          placement="right"
          :closable="false"
          :visible="editFarmAreaVisible"
          @close="onEditFarmAreaDrawerClose"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model
              :model="farmAreaFormValue"
              ref="editFarmAreaRefForm"
              :rules="farmAreaRules"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 14 }"
            >
              <a-form-model-item label="简称" prop="name">
                <a-input v-model="farmAreaFormValue.name" placeholder="请输入简称"/>
              </a-form-model-item>
              <a-form-model-item label="全称" prop="fullName">
                <a-input v-model="farmAreaFormValue.fullName" placeholder="请输入全称"/>
              </a-form-model-item>
              <a-form-model-item label="基地负责人" prop="contact">
                <a-input v-model="farmAreaFormValue.contact" placeholder="请输入基地负责人"/>
              </a-form-model-item>
              <a-form-model-item label="地址" prop="address">
                <a-input v-model="farmAreaFormValue.address" placeholder="请输入地址"/>
              </a-form-model-item>
              <a-form-model-item label="面积" prop="acreage">
                <a-input v-model="farmAreaFormValue.acreage" type="number" placeholder="请输入面积(数字)"/>
              </a-form-model-item>
              <a-form-model-item label="种植" prop="plantAcreage">
                <a-input v-model="farmAreaFormValue.plantAcreage" placeholder="请输入种植面积"/>
              </a-form-model-item>
              <a-form-model-item label="地租" prop="rent">
                <a-input v-model="farmAreaFormValue.rent" placeholder="请输入地租"/>
              </a-form-model-item>
              <a-form-model-item label="海拔" prop="elevation">
                <a-input v-model="farmAreaFormValue.elevation" placeholder="请输入海拔"/>
              </a-form-model-item>
              <a-form-model-item label="地势" prop="terrainList">
                <a-checkbox
                  :checked="farmAreaFormValue.terrainList.includes('FLAT')"
                  @change="terrainListCheckboxChange('FLAT')"
                >
                  平地
                </a-checkbox>
                <a-checkbox
                  :checked="farmAreaFormValue.terrainList.includes('SLOPING')"
                  @change="terrainListCheckboxChange('SLOPING')"
                >
                  坡地
                </a-checkbox>
                <a-checkbox
                  :checked="farmAreaFormValue.terrainList.includes('TERRACED')"
                  @change="terrainListCheckboxChange('TERRACED')"
                >
                  梯田
                </a-checkbox>
              </a-form-model-item>
              <a-form-model-item label="种植季节" prop="plantingTimeList">
                <a-checkbox
                  :checked="
                    farmAreaFormValue.plantingTimeList.includes('SPRING')
                  "
                  @change="plantingTimeListCheckboxChange('SPRING')"
                >
                  春
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.plantingTimeList.includes('SUMMER')
                  "
                  @change="plantingTimeListCheckboxChange('SUMMER')"
                >
                  夏
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.plantingTimeList.includes('AUTUMN')
                  "
                  @change="plantingTimeListCheckboxChange('AUTUMN')"
                >
                  秋
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.plantingTimeList.includes('WINTER')
                  "
                  @change="plantingTimeListCheckboxChange('WINTER')"
                >
                  冬
                </a-checkbox>
              </a-form-model-item>

              <a-form-model-item label="灌溉方式" prop="irrigationMethodList">
                <a-checkbox
                  :checked="
                    farmAreaFormValue.irrigationMethodList.includes('TRICKLE')
                  "
                  @change="irrigationMethodListCheckboxChange('TRICKLE')"
                >
                  滴灌
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.irrigationMethodList.includes(
                      'SPRINKLING'
                    )
                  "
                  @change="irrigationMethodListCheckboxChange('SPRINKLING')"
                >
                  喷灌
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.irrigationMethodList.includes('FURROW')
                  "
                  @change="irrigationMethodListCheckboxChange('FURROW')"
                >
                  沟灌
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.irrigationMethodList.includes('MANPOWER')
                  "
                  @change="irrigationMethodListCheckboxChange('MANPOWER')"
                >
                  人工
                </a-checkbox>
              </a-form-model-item>
              <a-form-model-item label="水源状况" prop="waterConditionsList">
                <a-checkbox
                  :checked="
                    farmAreaFormValue.waterConditionsList.includes('RIVER')
                  "
                  @change="waterConditionsListCheckboxChange('RIVER')"
                >
                  河流溪流
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.waterConditionsList.includes('LAKES')
                  "
                  @change="waterConditionsListCheckboxChange('LAKES')"
                >
                  湖泊水库
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.waterConditionsList.includes('POND')
                  "
                  @change="waterConditionsListCheckboxChange('POND')"
                >
                  池塘
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.waterConditionsList.includes(
                      'UNDERGROUND'
                    )
                  "
                  @change="waterConditionsListCheckboxChange('UNDERGROUND')"
                >
                  地下水
                </a-checkbox>
              </a-form-model-item>
              <a-form-model-item label="土壤状况" prop="soilConditionsList">
                <a-checkbox
                  :checked="
                    farmAreaFormValue.soilConditionsList.includes('CLAY')
                  "
                  @change="soilConditionsListCheckboxChange('CLAY')"
                >
                  粘土
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.soilConditionsList.includes('LOAM')
                  "
                  @change="soilConditionsListCheckboxChange('LOAM')"
                >
                  壤土
                </a-checkbox>
                <a-checkbox
                  :checked="
                    farmAreaFormValue.soilConditionsList.includes('SANDY')
                  "
                  @change="soilConditionsListCheckboxChange('SANDY')"
                >
                  沙土
                </a-checkbox>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div
            :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button
              :style="{ marginRight: '8px' }"
              @click="editFarmAreaVisible = false"
            >
              取消
            </a-button>
            <a-button type="primary" @click="toEditFarmArea"> 确认 </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      farmAreaFormValue: {
        id: "",
        name: "",
        fullName: "",
        region: "",
        contact: "",
        address: "",
        acreage: "",
        plantAcreage: "",
        elevation: "",
        rent: "",
        terrainList: [],
        plantingTimeList: [],
        irrigationMethodList: [],
        waterConditionsList: [],
        soilConditionsList: [],
        terrain: "",
        plantingTime: "",
        irrigationMethod: "",
        waterConditions: "",
        soilConditions: "",
      },
      farmAreaRules: {
        name: [{ required: true, message: "请输入用户名!", trigger: "blur" }],
        fullName: [{ required: true, message: "请输入密码!", trigger: "blur" }],
        acreage: [{ required: true, message: "请输入面积!", trigger: "blur" }],
        contact: [
          { required: true, message: "请输入负责人!", trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入地址!", trigger: "blur" }],
      },
      spinning: true,
      screenWidth: document.documentElement.clientWidth, //屏幕宽度
      screenHeight: document.documentElement.clientHeight - 50, //屏幕高度
      addFarmAreaVisible: false,
      editFarmAreaVisible: false,
      data: [],
    };
  },
  watch: {
    screenWidth: function (val) {
      //监听屏幕宽度变化
      console.log(val);
    },
    screenHeight: function (val) {
      //监听屏幕高度变化
      console.log(val);
    },
  },
  mounted() {
    const _this = this;
    window.onresize = function () {
      // 定义窗口大小变更通知事件
      _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
  },
  created() {
    this.getFarmAreaData();
  },
  filters: {
    StateFiltr: function (value) {
      if (!value) return "";
      if (value === "USABLE") {
        return "正常";
      } else if (value === "LOCK") {
        return "锁定";
      } else if (value === "DISABLED") {
        return "禁用";
      } else {
        return "禁用";
      }
    },
    StateTypeFiltr: function (value) {
      if (!value) return "";
      if (value === "USABLE") {
        return "success";
      } else if (value === "LOCK") {
        return "锁定";
      } else if (value === "DISABLED") {
        return "warning";
      } else {
        return "danger";
      }
    },
  },
  computed: {
    pageHeight: function () {
      let height = document.documentElement.clientHeight;
      return height - 50;
    },
  },
  methods: {
    terrainListCheckboxChange(key) {
      let flag = this.farmAreaFormValue.terrainList.includes(key);
      if (flag) {
        this.farmAreaFormValue.terrainList.splice(
          this.farmAreaFormValue.terrainList.indexOf(key),
          1
        );
      } else {
        this.farmAreaFormValue.terrainList.push(key);
      }
    },
    plantingTimeListCheckboxChange(key) {
      let flag = this.farmAreaFormValue.plantingTimeList.includes(key);
      if (flag) {
        this.farmAreaFormValue.plantingTimeList.splice(
          this.farmAreaFormValue.plantingTimeList.indexOf(key),
          1
        );
      } else {
        this.farmAreaFormValue.plantingTimeList.push(key);
      }
    },
    irrigationMethodListCheckboxChange(key) {
      let flag = this.farmAreaFormValue.irrigationMethodList.includes(key);
      if (flag) {
        this.farmAreaFormValue.irrigationMethodList.splice(
          this.farmAreaFormValue.irrigationMethodList.indexOf(key),
          1
        );
      } else {
        this.farmAreaFormValue.irrigationMethodList.push(key);
      }
    },
    waterConditionsListCheckboxChange(key) {
      let flag = this.farmAreaFormValue.waterConditionsList.includes(key);
      if (flag) {
        this.farmAreaFormValue.waterConditionsList.splice(
          this.farmAreaFormValue.waterConditionsList.indexOf(key),
          1
        );
      } else {
        this.farmAreaFormValue.waterConditionsList.push(key);
      }
    },
    soilConditionsListCheckboxChange(key) {
      let flag = this.farmAreaFormValue.soilConditionsList.includes(key);
      if (flag) {
        this.farmAreaFormValue.soilConditionsList.splice(
          this.farmAreaFormValue.soilConditionsList.indexOf(key),
          1
        );
      } else {
        this.farmAreaFormValue.soilConditionsList.push(key);
      }
    },
    getFarmAreaData() {
      this.http.farmArea.getFarmAreaList().then((response) => {
        if (response.data.status == 200) {
          this.spinning = false;
          this.data = response.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },
    deleteFarmArea(key) {
      let self = this;
      this.$confirm({
        title: "确定删除吗?",
        content: "",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.http.farmArea.deleteFarmArea({ id: key.id }).then((res) => {
            if (res.data.status == 200) {
              this.$message.success(res.data.msg);
              self.getFarmAreaData();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        },
        onCancel() {},
      });
    },

    onAddFarmAreaDrawerClose() {
      this.addFarmAreaVisible = false;
    },
    onEditFarmAreaDrawerClose() {
      this.editFarmAreaVisible = false;
    },
    addFarmArea() {
      this.addFarmAreaVisible = true;
        this.$refs.farmAreaRefForm.resetFields()
      this.cleanFarmArea();
    },
    toAddFarmArea() {
      this.$refs.farmAreaRefForm.validate((valid) => {
        if (valid) {
          console.log(this.farmAreaFormValue);
          /*this.farmAreaFormValue.terrain = this.farmAreaFormValue.terrainList.join(",");
          this.farmAreaFormValue.plantingTime = this.farmAreaFormValue.plantingTimeList.join(",");
          this.farmAreaFormValue.irrigationMethod = this.farmAreaFormValue.irrigationMethodList.join(",");
          this.farmAreaFormValue.waterConditions = this.farmAreaFormValue.waterConditionsList.join(",");
          this.farmAreaFormValue.soilConditions = this.farmAreaFormValue.soilConditionsList.join(",");*/
          this.http.farmArea.createFarmArea(this.farmAreaFormValue)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                this.addFarmAreaVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getFarmAreaData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editFarmArea(record) {
      console.log(record);
      this.cleanFarmArea();
      this.farmAreaFormValue = {
        id: record.id,
        name: record.name,
        fullName: record.fullName,
        region: record.region,
        contact: record.contact,
        address: record.address,
        acreage: record.acreage,
        plantAcreage: record.plantAcreage,
        elevation: record.elevation,
        rent: record.rent,
        terrainList: record.terrainList,
        plantingTimeList: record.plantingTimeList,
        irrigationMethodList: record.irrigationMethodList,
        waterConditionsList: record.waterConditionsList,
        soilConditionsList: record.soilConditionsList,
      };
      this.editFarmAreaVisible = true;
        this.$refs.editFarmAreaRefForm.resetFields()
    },
    cleanFarmArea() {
      console.log('clear')
      this.farmAreaFormValue = {
        id: "",
        name: "",
        fullName: "",
        region: "",
        contact: "",
        address: "",
        acreage: "",
        plantAcreage: "",
        elevation: "",
        rent: "",
        terrainList: [],
        plantingTimeList: [],
        irrigationMethodList: [],
        waterConditionsList: [],
        soilConditionsList: [],
        terrain: "",
        plantingTime: "",
        irrigationMethod: "",
        waterConditions: "",
        soilConditions: "",
      };
    },

    toEditFarmArea() {
      this.$refs.editFarmAreaRefForm.validate((valid) => {
        if (valid) {
          this.http.farmArea.updateFarmArea(this.farmAreaFormValue)
            .then((response) => {
              if (response.data.status == 200) {
                this.$message.success(response.data.msg);
                this.editFarmAreaVisible = false;
              } else {
                this.$message.warning(response.data.msg);
              }
              this.getFarmAreaData();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
